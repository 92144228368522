import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate} from 'react-router-dom';
import {analytics} from './firebase';
import {logEvent} from 'firebase/analytics';

import JsonParser from './components/parsers/JsonParser';
import UrlParser from './components/parsers/UrlParser';
import XmlParser from './components/parsers/XmlParser';
import YamlParser from './components/parsers/YamlParser';
import CsvParser from './components/parsers/CsvParser';
import JsonConverter from './components/converters/JsonConverter';
import TimestampConverter from './components/converters/TimestampConverter';
import TextHexConverter from './components/converters/TextHexConverter';
import HtmlEscapeUnescape from './components/escape/HtmlEscapeUnescape';
import JavaScriptEscapeUnescape from './components/escape/JavaScriptEscapeUnescape';

import UnitConverter from './components/converters/UnitConverter';
import BaseConverter from './components/converters/BaseConverter';
import JsonEscapeUnescape from './components/escape/JsonEscapeUnescape';
import XmlEscapeUnescape from './components/escape/XmlEscapeUnescape';
import SqlEscapeUnescape from './components/escape/SqlEscapeUnescape';
import StringUtilities from './components/texttools/StringUtilities';
import CaseConverter from './components/texttools/CaseConverter';
import SortLines from './components/texttools/SortLines';
import DiffChecker from './components/texttools/DiffChecker';
import TextEditor from './components/texttools/TextEditor';
import JsonEditor from './components/texttools/JsonEditor';
import LoremIpsumGenerator from './components/texttools/LoremIpsumGenerator';
import SlugGenerator from './components/texttools/SlugGenerator';
import HtmlStripper from './components/texttools/HtmlStripper';
import Pastebin from './components/texttools/Pastebin';
import HashGenerator from './components/crypto/HashGenerator';
import HmacGenerator from './components/crypto/HmacGenerator';
import ChecksumCalculator from './components/crypto/ChecksumCalculator';
import PasswordGenerator from './components/crypto/PasswordGenerator';
import UuidGenerator from './components/crypto/UuidGenerator';


import ImageResizerCompressor  from './components/imagetools/resizer-compressor';
import ColorPicker from './components/imagetools/ColorPicker';
import QrCodeGenerator from './components/imagetools/QrCodeGenerator';
import QrCodeReader from './components/imagetools/QrCodeReader';
import ExifViewer from './components/imagetools/ExifViewer';
import MyIp from './components/networktools/MyIp';
import IpLookup from './components/networktools/IpLookup';
import IpNumberConverter from './components/networktools/IpNumberConverter';
import CidrIpConverter from './components/networktools/CidrIpConverter';
import WhoisLookup from './components/networktools/WhoisLookup';

import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsOfService from './components/legal/TermsOfService';
import ContactUs from './components/legal/ContactUs';
import AboutUs from './components/legal/AboutUs';

// New imports for tutorials and templates
import JsonBasics from './components/tutorials/JsonBasics';
import JsonRestApi from './components/tutorials/JsonRestApi';
import JsonFaq from './components/tutorials/JsonFaq';
import JsonTemplates from './components/tutorials/JsonTemplates';



function GetTitle() {
  const location = useLocation();
  const path = location.pathname.split('/').filter(Boolean);
  if (path.length > 1) {
    return path[1].charAt(0).toUpperCase() + path[1].slice(1).replace('-', ' ');
  }
  return 'Home';
}

const Footer = () => {
  return (
      <footer className="bg-gray-800 text-white p-4 fixed bottom-0 left-0 right-0 z-10">
        <div className="container mx-auto flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 text-center md:text-left mb-2 md:mb-0">
            <span>&copy; 2024 json-parser.org. All rights reserved.</span>
          </div>
          <div className="w-full md:w-2/3">
            <ul className="flex flex-wrap justify-center md:justify-end">
              <li className="mx-2"><Link to="/about" className="hover:text-gray-300">About Us</Link></li>
              <li className="mx-2"><Link to="/contact" className="hover:text-gray-300">Contact Us</Link></li>
              <li className="mx-2"><Link to="/privacy" className="hover:text-gray-300">Privacy Policy</Link></li>
              <li className="mx-2"><Link to="/terms" className="hover:text-gray-300">Terms of Service</Link></li>
            </ul>
          </div>
        </div>
      </footer>
  );
};

function AppContent() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_title: 'Home',
        page_location: window.location.href,
        page_path: window.location.pathname
      });
    }
  }, []);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  };

  const MenuItem = ({to, children}) => (
      <Link to={to} className="block py-1 px-4 hover:bg-gray-700 rounded transition duration-200"
            onClick={closeSideMenu}>
        {children}
      </Link>
  );

  const MenuCategory = ({title, children}) => (
      <div className="mb-2">
        <h3 className="mb-1 text-sm font-semibold text-gray-400 uppercase">{title}</h3>
        <div className="space-y-1">{children}</div>
      </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex flex-1 overflow-hidden">
        {/* Side Menu */}
        <div
            className={`bg-gray-800 text-white w-64 space-y-2 py-4 px-2 absolute inset-y-0 left-0 transform ${
                isSideMenuOpen ? 'translate-x-0' : '-translate-x-full'
            } md:relative md:translate-x-0 transition duration-200 ease-in-out overflow-y-auto`}
            style={{maxHeight: 'calc(100vh - 4rem)'}}
        >
          <nav>
            <MenuCategory title="Parser">
              <MenuItem to="/parser/json">JSON Parser</MenuItem>
              <MenuItem to="/parser/xml">XML Parser</MenuItem>
              <MenuItem to="/parser/yaml">YAML Parser</MenuItem>
              <MenuItem to="/parser/csv">CSV Parser</MenuItem>
              <MenuItem to="/parser/url">URL Parser</MenuItem>
            </MenuCategory>
            <MenuCategory title="Converter">
              <MenuItem to="/converter/json">JSON Converter</MenuItem>
              <MenuItem to="/converter/timestamp">Timestamp Converter</MenuItem>
              <MenuItem to="/converter/text-hex">Text / Hex Converter</MenuItem>
              <MenuItem to="/converter/unit">Unit Converter</MenuItem>
              <MenuItem to="/converter/base">Base Converter</MenuItem>
            </MenuCategory>
            <MenuCategory title="Tutorials & Templates">
              <MenuItem to="/tutorials/json-basics">JSON Basics</MenuItem>
              <MenuItem to="/tutorials/json-rest-api">JSON in REST APIs</MenuItem>
              <MenuItem to="/tutorials/json-faq">JSON FAQ</MenuItem>
              <MenuItem to="/tools/json-templates">JSON Templates</MenuItem>
            </MenuCategory>
            <MenuCategory title="Image Tools">
              <MenuItem to="/imagetools/resizer-compressor">Image resize</MenuItem>
              <MenuItem to="/imagetools/color-picker">Color Picker</MenuItem>
              <MenuItem to="/imagetools/qr-code-generator">QR Code Generator</MenuItem>
              <MenuItem to="/imagetools/qr-code-reader">QR Code Reader</MenuItem>
              <MenuItem to="/imagetools/exif-viewer">EXIF Viewer</MenuItem>
            </MenuCategory>
            <MenuCategory title="Escape / Unescape">
              <MenuItem to="/escape/html">HTML Escape / Unescape</MenuItem>
              <MenuItem to="/escape/javascript">JavaScript Escape / Unescape</MenuItem>
              <MenuItem to="/escape/json">JSON Escape / Unescape</MenuItem>
              <MenuItem to="/escape/xml">XML Escape / Unescape</MenuItem>
              <MenuItem to="/escape/sql">SQL Escape / Unescape</MenuItem>
              
            </MenuCategory>
            <MenuCategory title="Text Tools">
              <MenuItem to="/texttools/string-utilities">String Utilities</MenuItem>
              <MenuItem to="/texttools/case-converter">Case Converter</MenuItem>
              <MenuItem to="/texttools/sort-lines">Sort Lines</MenuItem>
              <MenuItem to="/texttools/diff-checker">Diff Checker</MenuItem>
              <MenuItem to="/texttools/text-editor">Text Editor</MenuItem>
              <MenuItem to="/texttools/json-editor">JSON Editor</MenuItem>
              <MenuItem to="/texttools/lorem-ipsum">Lorem Ipsum Generator</MenuItem>
              <MenuItem to="/texttools/slug-generator">Slug Generator</MenuItem>
              <MenuItem to="/texttools/html-stripper">HTML Stripper</MenuItem>
              {/*<MenuItem to="/texttools/pastebin">Pastebin</MenuItem>*/}
            </MenuCategory>
            <MenuCategory title="Cryptography and Security">
              <MenuItem to="/crypto/hash-generator">Hash Generator</MenuItem>
              <MenuItem to="/crypto/hmac-generator">HMAC Generator</MenuItem>
              <MenuItem to="/crypto/checksum-calculator">Checksum Calculator</MenuItem>
              <MenuItem to="/crypto/password-generator">Password Generator</MenuItem>
              <MenuItem to="/crypto/uuid-generator">UUID Generator</MenuItem>
            </MenuCategory>

            {/*<MenuCategory title="Network Tools">
              <MenuItem to="/networktools/my-ip">My IP</MenuItem>
              <MenuItem to="/networktools/ip-lookup">IP Lookup</MenuItem>
              <MenuItem to="/networktools/ip-number-converter">IP / Number Converter</MenuItem>
              <MenuItem to="/networktools/cidr-ip-converter">CIDR / IP Converter</MenuItem>
              <MenuItem to="/networktools/whois-lookup">WHOIS Lookup</MenuItem>
            </MenuCategory>*/}
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Top Navigation Bar */}
          <header className="bg-white shadow-md">
            <div className="container mx-auto px-4 py-3 flex justify-between items-center">
              <div className="flex flex-col items-start">
                <h1 className="text-xl font-semibold">DevToolOnline</h1>
                <h2 className="text-sm font-medium text-gray-600"><GetTitle/></h2>
              </div>
              <button onClick={toggleSideMenu}
                      className="md:hidden text-gray-500 hover:text-gray-600">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M4 6h16M4 12h16M4 18h16"/>
                </svg>
              </button>
            </div>
          </header>

          {/* Page Content */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div className="container mx-auto px-6 py-8">
              <Routes>
                <Route path="/" element={<Navigate replace to="/parser/json"/>}/>
                <Route path="/parser/json" element={<JsonParser/>}/>
                <Route path="/parser/url" element={<UrlParser/>}/>
                <Route path="/parser/xml" element={<XmlParser/>}/>
                <Route path="/parser/yaml" element={<YamlParser/>}/>
                <Route path="/parser/csv" element={<CsvParser/>}/>
                <Route path="/converter/json" element={<JsonConverter/>}/>
                <Route path="/converter/timestamp" element={<TimestampConverter/>}/>
                <Route path="/converter/text-hex" element={<TextHexConverter/>}/>
                <Route path="/converter/unit" element={<UnitConverter/>}/>
                <Route path="/converter/base" element={<BaseConverter/>}/>
                <Route path="/escape/html" element={<HtmlEscapeUnescape/>}/>
                <Route path="/escape/javascript" element={<JavaScriptEscapeUnescape/>}/>
                <Route path="/escape/json" element={<JsonEscapeUnescape/>}/>
                <Route path="/escape/xml" element={<XmlEscapeUnescape/>}/>
                <Route path="/escape/sql" element={<SqlEscapeUnescape/>}/>
                <Route path="/texttools/string-utilities" element={<StringUtilities/>}/>
                <Route path="/texttools/case-converter" element={<CaseConverter/>}/>
                <Route path="/texttools/sort-lines" element={<SortLines/>}/>
                <Route path="/texttools/diff-checker" element={<DiffChecker/>}/>
                <Route path="/texttools/text-editor" element={<TextEditor/>}/>
                <Route path="/texttools/json-editor" element={<JsonEditor/>}/>
                <Route path="/texttools/lorem-ipsum" element={<LoremIpsumGenerator/>}/>
                <Route path="/texttools/slug-generator" element={<SlugGenerator/>}/>
                <Route path="/texttools/html-stripper" element={<HtmlStripper/>}/>
                <Route path="/texttools/pastebin" element={<Pastebin/>}/>
                <Route path="/crypto/hash-generator" element={<HashGenerator/>}/>
                <Route path="/crypto/hmac-generator" element={<HmacGenerator/>}/>
                <Route path="/crypto/checksum-calculator" element={<ChecksumCalculator/>}/>
                <Route path="/crypto/password-generator" element={<PasswordGenerator/>}/>
                <Route path="/crypto/uuid-generator" element={<UuidGenerator/>}/>
                <Route path="/imagetools/resizer-compressor" element={<ImageResizerCompressor />} />
                <Route path="/imagetools/color-picker" element={<ColorPicker/>}/>
                <Route path="/imagetools/qr-code-generator" element={<QrCodeGenerator/>}/>
                <Route path="/imagetools/qr-code-reader" element={<QrCodeReader/>}/>
                <Route path="/imagetools/exif-viewer" element={<ExifViewer/>}/>
                <Route path="/networktools/my-ip" element={<MyIp/>}/>
                <Route path="/networktools/ip-lookup" element={<IpLookup/>}/>
                <Route path="/networktools/ip-number-converter" element={<IpNumberConverter/>}/>
                <Route path="/networktools/cidr-ip-converter" element={<CidrIpConverter/>}/>
                <Route path="/networktools/whois-lookup" element={<WhoisLookup/>}/>
                <Route path="/about" element={<AboutUs/>}/>
                <Route path="/contact" element={<ContactUs/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/terms" element={<TermsOfService/>}/>
                <Route path="/tutorials/json-basics" element={<JsonBasics/>}/>
                <Route path="/tutorials/json-rest-api" element={<JsonRestApi/>}/>
                <Route path="/tutorials/json-faq" element={<JsonFaq/>}/>
                <Route path="/tools/json-templates" element={<JsonTemplates/>}/>
              </Routes>
            </div>
          </main>
        </div>
      </div>
      {!location.pathname.startsWith('/blog/') && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;